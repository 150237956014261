<template>
  <div class="bimbel-wrapper">
    <!-- bimbels -->
    <b-form-group class="bimbel-search">
      <b-input-group class="input-group-merge">
        <b-form-input id="search-input" placeholder="Cari Disini" />
        <b-input-group-append class="cursor-pointer" is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-row class="blog-list-wrapper">
      <b-col md="3" v-for="bimbel in dataBimbel" v-bind:key="bimbel.id">
        <b-card tag="article" no-body oncontextmenu="return false;">
          <!-- <b-link :href="'/bimbel/detail/' + bimbel.id"> -->
          <!-- <b-img
              class="card-img-top"
              :src="getUrl + 'penyimpanan/bimbel/' + bimbel.photo"
            /> -->
          <video width="100%" height="auto" controls controlslist="nodownload">
            <source
              :src="getUrl + 'penyimpanan/bimbel/' + bimbel.photo"
              type="video/mp4"
            />
          </video>
          <!-- <b-embed type="iframe" aspect="16by9" :src="getUrl + 'penyimpanan/bimbel/' + bimbel.photo" nodownload allowfullscreen/> -->
          <!-- </b-link> -->
          <b-card-body>
            <b-card-title>
              <h5>{{ bimbel.title }}</h5>
            </b-card-title>
            <b-media no-body>
              <b-link class="brand-logo" href="/">
                <b-img
                  :src="require('@/assets/images/avatars/13-small.png')"
                  style="width: 20px; height: 20px"
                  alt="logo"
                  class=""
                ></b-img>
              </b-link>
              <b-media-body>
                <small class="text mr-50"> by</small>
                <small>
                  <b-link class="text-body">Optimal</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text">Bimbingan Belajar</small>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <!--/ bimbels -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BEmbed,
} from "bootstrap-vue";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BEmbed,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: "",
      currentPage: 1,
      perPage: 1,
      rows: 50,
      dataBimbel: [],
      dataProduct: [],
      selected: "left",
      selected2: "right",
      level: localStorage.getItem("Ulevel"),
      getUrl: "",
    };
  },

  async mounted() {
    await this.getData();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "bimbels/view")
        .then((res) => {
          this.dataBimbel = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    disabledmouse() {
      $("#videoElementID").bind("contextmenu", function () {
        return false;
      });
    },

    //   hideContextMenu() {
    //     //  document.getElementById("div-context-menu").style.display = "none"
    //     // document.querySelector('video').addEventListener('contextmenu', disableRightClick);
    //     // e.preventDefault();
    //     // alert("Maaf, Video Tidak Bisa didownload")
    //     function() {
    //     this.$nextTick(function () {
    //       $(function () {
    //         $("video").on("contextmenu", function (e) {
    //           return false;
    //         });
    //       });
    //     });
    //   }
    // }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #cc0000;
}
</style>
